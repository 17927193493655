import React from "react"

const LogoSvg = ({ width, height, className }) => {
  return (
    <svg
      version="1.0"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 500 157"
    >
      <path d="M32.5 8.4c-3.9 1.8-8 6.2-9.9 10.8-1.4 3.2-1.6 11.5-1.6 60.6v56.9l2.3 4.4c1.6 2.9 4 5.4 7.2 7.4l4.8 3h57.1c56 0 57.2 0 61.1-2.1 2.2-1.1 5.4-4 7-6.3l3-4.3.3-58.1.3-58.1-2.6-5.3c-1.9-3.9-3.8-6-6.9-7.8L150.3 7H92.9c-45.7.1-58 .4-60.4 1.4zM144 21.8c7.2 3.6 7.1 2.7 6.8 51.8l-.3 43.9-6.3-5.9c-3.5-3.4-6.6-5.5-7-5-.5.5-2.5 3-4.5 5.6-2 2.5-5.9 6.2-8.6 8.2l-4.9 3.6 6.4 6.2c3.5 3.4 6.4 6.5 6.4 7 0 .4-19.4.8-43 .8-46.7 0-47.6-.1-51.8-5.5-2.2-2.8-2.2-3-2.2-53.4 0-55.9-.3-53.1 6.4-57.2C44.3 20.1 47 20 92.5 20c43.7 0 48.3.2 51.5 1.8z" />
      <path d="M78.8 35.9C46.7 46.2 37 87.5 61 111c5.8 5.7 13.9 10.3 21.3 12 10.7 2.4 24.4.3 33.9-5.3 10.4-6 18.7-18.9 20.8-32 2.7-17.3-6.2-36.9-20.8-45.4-10.6-6.3-25.9-8-37.4-4.4zm26.6 10.2c15.2 5.6 25 23.3 21.7 39.1-3.5 17-15.7 27.6-32.6 28.6-6.6.3-9.1 0-14-1.9-11.2-4.2-20.5-15.7-22.4-27.7C55.5 67.3 66.5 50 83 44.9c5.2-1.5 16.8-1 22.4 1.2z" />
      <path d="M85 56.3c-4.9 1.6-13.3 10.2-14.8 15.3-4.2 14.2 4.9 28.6 19.5 31 8.9 1.4 19.5-4.3 23.7-12.8 9.5-19-8.2-39.9-28.4-33.5zm1.4 15.8c2.9 3.5 3.4 5.4 1 4.5-.9-.3-2.2-.6-2.9-.6-2.4 0-5.7-3.4-5-5.3 1.1-2.6 4-2 6.9 1.4zM412 77.5V114h15V41h-15v36.5zM218.7 48c-4.6 1.4-9.8 6-11.8 10.4-1.6 3.5-1.9 6.7-1.9 22.9 0 20.2.5 22.8 5.4 27.9 5.5 5.8 6.4 5.9 34.7 5.8h26.1l2.7 2.9 2.8 3 4.1-4.2 4.2-4.3-2.1-2.1c-2.1-2-2.1-2.1-.2-5.4 1.5-2.9 1.9-6.2 2.1-21 .2-11.1-.1-19.2-.8-21.8-1.5-5.5-8.1-12.7-12.9-14.1-4.3-1.2-48.4-1.2-52.4 0zm45.4 12.8c5.7 3.5 6.9 7.2 6.9 20.4 0 12.2-.8 15.1-3.4 12.9-1.3-1.1-2.2-.7-6 2.6-3.2 2.8-4.1 4.1-3.4 5 1.7 2.1-1.2 2.5-16.1 2.1-15.2-.3-16.8-.9-21.5-7.2-2.9-3.8-3.6-21.6-1.2-28 3.4-8.9 8-10.7 27.1-10.4 12.6.3 14.4.5 17.6 2.6zM298 57v7h14c7.7 0 14 .3 14 .6s-7 9.6-15.5 20.5c-14.7 19-15.5 20.2-15.5 24.4v4.5h56v-13.7l-14.2-.7c-7.9-.4-14.4-.9-14.6-1.1-.2-.2 6.2-9.1 14.2-19.7C349.6 61.2 351 58.9 351 55.3v-4l-5.2-.7c-2.9-.3-14.9-.6-26.5-.6H298v7zM375.7 64.4c-11.1 3-17.8 11.4-18.5 23.3-.8 11.5 3.4 20 12.1 24.5 5.1 2.6 17.5 3.1 25 .9l4.7-1.4v-10.5l-3.7 1.4c-5 1.8-14.7 1.8-18.2 0-2.5-1.3-5.1-5.2-5.1-7.6 0-.6 3.3-1 7.3-1 3.9 0 10.9-.3 15.5-.7l8.2-.6V87c0-3.1-.5-7.2-1.1-9.1-3.6-10.7-14.9-16.5-26.2-13.5zM387 76c1.1 1.1 2 2.6 2 3.4 0 .8.3 2.1.6 3 .5 1.4-.6 1.6-8.5 1.6-5.7 0-9.1-.4-9.1-1 0-2 3-6.9 4.7-7.9 3-1.7 8.1-1.3 10.3.9zM452.2 64.1c-2.9.5-6.6 1.6-8.2 2.4-2.8 1.4-3 2-3 6.9v5.3l4.2-2c2.4-1.2 6.9-2.3 10.4-2.5 5.3-.4 6.5-.1 8.3 1.7 1.2 1.2 2.1 3 2.1 4 0 2.1-1.2 2.5-11 3.7-11.5 1.4-17.3 5.8-18.5 13.8-.9 5.7.8 10.8 4.5 14 6 5 15.7 4.6 21.5-.8l3.5-3.3V114h14V97.2c0-9.2-.5-18.5-1.1-20.7-2.7-9.8-13.2-14.7-26.7-12.4zM466 93.9c0 5.6-3.9 10.1-8.8 10.1-5.6 0-8.4-3.6-6.6-8.4.9-2.3 3.7-3.6 9.2-4.3 2.6-.3 4.9-.7 5.1-.9.9-.8 1.1-.3 1.1 3.5z" />
    </svg>
  )
}

// const play = ({width, height, className}) => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512"><path d="M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm101.771 264.969l-149.333 96a10.62 10.62 0 01-5.771 1.698c-1.75 0-3.521-.438-5.104-1.302A10.653 10.653 0 01192 352V160c0-3.906 2.125-7.49 5.563-9.365 3.375-1.854 7.604-1.74 10.875.396l149.333 96c3.042 1.958 4.896 5.344 4.896 8.969s-1.854 7.01-4.896 8.969z"/></svg>
// )

const Play = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path d="M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm101.771 264.969l-149.333 96a10.62 10.62 0 01-5.771 1.698c-1.75 0-3.521-.438-5.104-1.302A10.653 10.653 0 01192 352V160c0-3.906 2.125-7.49 5.563-9.365 3.375-1.854 7.604-1.74 10.875.396l149.333 96c3.042 1.958 4.896 5.344 4.896 8.969s-1.854 7.01-4.896 8.969z" />
    </svg>
  )
}

const Close = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 492 492"
    >
      <path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z" />
    </svg>
  )
}

const Facebook = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 430.113 430.114"
    >
      <path d="M158.081,83.3c0,10.839,0,59.218,0,59.218h-43.385v72.412h43.385v215.183h89.122V214.936h59.805c0,0,5.601-34.721,8.316-72.685c-7.784,0-67.784,0-67.784,0s0-42.127,0-49.511c0-7.4,9.717-17.354,19.321-17.354c9.586,0,29.818,0,48.557,0c0-9.859,0-43.924,0-75.385c-25.016,0-53.476,0-66.021,0C155.878-0.004,158.081,72.48,158.081,83.3z" />
    </svg>
  )
}

const Twitter = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 -47 512.002 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M191.012 419.043c-22.14 0-44.93-1.793-67.856-5.387-40.379-6.336-81.254-27.457-92.82-33.781L0 363.289l32.844-10.8c35.902-11.805 57.742-19.13 84.777-30.598-27.07-13.11-47.933-36.692-57.976-67.176l-7.641-23.195 6.266.957A113.939 113.939 0 0144 214.883c-12.934-19.645-19.781-43.649-18.324-64.219l1.437-20.246 12.121 4.695a113.316 113.316 0 01-10.98-30.777c-5.293-26.36-.863-54.363 12.476-78.852L51.29 6.102l14.12 16.96c44.66 53.649 101.227 85.473 168.363 94.79-2.742-18.903-.687-37.145 6.114-53.497 7.918-19.039 22.004-35.183 40.722-46.69 20.79-12.778 46-18.97 70.989-17.435 26.511 1.63 50.582 11.563 69.699 28.747 9.336-2.426 16.215-5.016 25.512-8.516 5.593-2.106 11.937-4.496 19.875-7.23l29.25-10.079-19.075 54.477a96.355 96.355 0 013.91-.254l31.235-1.414-18.461 25.23c-1.059 1.446-1.328 1.856-1.703 2.422-1.488 2.242-3.34 5.032-28.68 38.867-6.344 8.473-9.512 19.508-8.922 31.079 2.246 43.968-3.148 83.75-16.043 118.234-12.195 32.625-31.093 60.617-56.164 83.2-31.023 27.937-70.582 47.066-117.582 56.847-23.054 4.797-47.812 7.203-73.437 7.203zm0 0" />
    </svg>
  )
}

const Instagram = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.004 5.838a6.157 6.157 0 00-6.158 6.158 6.157 6.157 0 006.158 6.158 6.157 6.157 0 006.158-6.158 6.157 6.157 0 00-6.158-6.158zm0 10.155a3.996 3.996 0 113.997-3.997 3.995 3.995 0 01-3.997 3.997z" />
      <path d="M16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941C-.283 4.325.012 7.435.012 11.996c0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z" />
      <circle cx="18.406" cy="5.595" r="1.439" />
    </svg>
  )
}

const Youtube = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 438.536 438.536"
    >
      <path d="M414.41 24.123C398.333 8.042 378.963 0 356.315 0H82.228C59.58 0 40.21 8.042 24.126 24.123 8.045 40.207.003 59.576.003 82.225v274.084c0 22.647 8.042 42.018 24.123 58.102 16.084 16.084 35.454 24.126 58.102 24.126h274.084c22.648 0 42.018-8.042 58.095-24.126 16.084-16.084 24.126-35.454 24.126-58.102V82.225c-.001-22.649-8.043-42.021-24.123-58.102zM258.958 63.378h19.13v77.654c0 4.57.089 7.045.28 7.426.192 3.046 1.719 4.567 4.572 4.567 3.806 0 7.807-2.948 11.991-8.848V63.378h19.13v105.637h-19.137V157.59c-7.803 8.563-15.129 12.85-21.98 12.85-6.088 0-10.181-2.568-12.278-7.708-1.144-3.424-1.708-8.66-1.708-15.704v-83.65zM186.725 97.64c0-11.038 1.999-19.32 5.996-24.838 5.138-7.232 12.559-10.85 22.266-10.85 9.329 0 16.754 3.621 22.268 10.85 4 5.519 5.999 13.8 5.999 24.838v37.117c0 11.421-1.999 19.701-5.999 24.839-5.514 7.236-12.939 10.852-22.268 10.852-9.707 0-17.128-3.619-22.266-10.852-3.997-5.52-5.996-13.8-5.996-24.839V97.64zm-51.394-70.521l15.133 55.677 14.56-55.677h21.414l-25.697 84.51h.004v57.386h-21.127v-57.386c-2.096-10.468-6.473-25.505-13.134-45.108l-6.567-19.701-6.855-19.701h22.269zm235.262 344.61c-1.903 8.18-5.948 15.126-12.139 20.842-6.181 5.708-13.363 9.041-21.55 9.996-26.073 2.851-65.288 4.28-117.628 4.28-52.344 0-91.553-1.43-117.628-4.28-8.183-.955-15.367-4.288-21.555-9.996-6.186-5.716-10.229-12.662-12.133-20.842-3.805-15.991-5.708-40.737-5.708-74.233 0-32.935 1.903-57.67 5.708-74.233 1.905-8.375 5.948-15.371 12.133-20.988 6.188-5.617 13.466-8.901 21.842-9.851 25.882-2.859 64.997-4.284 117.341-4.284 52.535 0 91.746 1.429 117.628 4.284 8.187.953 15.41 4.233 21.693 9.851 6.283 5.614 10.38 12.609 12.282 20.988 3.61 15.797 5.421 40.539 5.421 74.233.001 33.495-1.903 58.237-5.707 74.233z" />
      <path d="M85.651 244.678h22.27v120.767h21.127V244.678h22.841v-19.986H85.651zM190.149 340.607c-4.187 5.896-8.186 8.847-11.991 8.847-2.665 0-4.093-1.427-4.283-4.281-.191-.38-.287-2.854-.287-7.426v-77.088h-18.843v82.796c0 7.234.572 12.374 1.715 15.413 1.712 5.332 5.711 7.994 11.991 7.994 7.042 0 14.275-4.281 21.698-12.847v11.424h19.126V260.666h-19.126v79.941zM264.099 259.235c-6.852 0-13.326 3.806-19.411 11.423v-45.966h-19.13v140.753h19.13v-10.279c6.276 7.802 12.755 11.703 19.411 11.703 7.806 0 12.847-3.997 15.126-11.991 1.334-3.997 1.998-10.944 1.998-20.841v-41.682c0-10.089-.671-17.132-1.998-21.129-2.28-7.994-7.321-11.991-15.126-11.991zm-1.715 76.233c0 9.325-2.758 13.982-8.281 13.989-3.23 0-6.376-1.525-9.422-4.571v-63.954c3.046-3.038 6.191-4.564 9.422-4.564 5.523 0 8.281 4.753 8.281 14.271v44.829zM333.466 335.324c0 1.998-.049 3.565-.137 4.709-.093 1.143-.145 1.81-.145 1.998-1.14 4.945-3.997 7.419-8.565 7.419-6.472 0-9.709-4.853-9.709-14.558v-18.555h37.973v-21.703c0-11.037-1.995-19.219-5.995-24.551-5.134-7.231-12.56-10.852-22.269-10.852s-17.319 3.62-22.843 10.852c-3.806 5.14-5.708 13.329-5.708 24.551v36.832c0 11.231 1.998 19.418 5.995 24.558 5.523 7.231 13.23 10.852 23.131 10.852 10.088 0 17.699-3.806 22.843-11.423 2.282-3.429 3.716-7.234 4.284-11.42.377-2.666.568-6.759.568-12.278V329.185h-19.417v6.14h-.006zm-18.549-44.399c0-9.705 3.142-14.558 9.422-14.558 6.283 0 9.421 4.853 9.421 14.558v9.712h-18.843v-9.712zM214.987 153.025c6.091 0 9.135-4.851 9.135-14.558v-44.54c0-9.895-3.044-14.847-9.135-14.847-6.09 0-9.135 4.952-9.135 14.847v44.54c0 9.704 3.045 14.558 9.135 14.558z" />
    </svg>
  )
}

const Linkedin = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 430.117 430.117"
    >
      <path d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558zM5.477,420.56h92.184v-277.32H5.477V420.56z" />
    </svg>
  )
}

const Whatsapp = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 90 90"
    >
      <path d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z" />
    </svg>
  )
}

const Mail = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path d="M10.688 95.156C80.958 154.667 204.26 259.365 240.5 292.01c4.865 4.406 10.083 6.646 15.5 6.646 5.406 0 10.615-2.219 15.469-6.604 36.271-32.677 159.573-137.385 229.844-196.896 4.375-3.698 5.042-10.198 1.5-14.719C494.625 69.99 482.417 64 469.333 64H42.667c-13.083 0-25.292 5.99-33.479 16.438-3.542 4.52-2.875 11.02 1.5 14.718zM505.813 127.406a10.618 10.618 0 00-11.375 1.542c-46.021 39.01-106.656 90.552-152.385 129.885a10.654 10.654 0 00-3.708 8.271 10.644 10.644 0 004 8.135c42.49 34.031 106.521 80.844 152.76 114.115a10.626 10.626 0 006.229 2.01c1.667 0 3.333-.385 4.865-1.177a10.66 10.66 0 005.802-9.49V137.083a10.663 10.663 0 00-6.188-9.677zM16.896 389.354c46.25-33.271 110.292-80.083 152.771-114.115a10.646 10.646 0 004-8.135 10.654 10.654 0 00-3.708-8.271C124.229 219.5 63.583 167.958 17.563 128.948a10.674 10.674 0 00-11.375-1.542A10.66 10.66 0 000 137.083v243.615c0 4 2.24 7.667 5.802 9.49a10.566 10.566 0 004.865 1.177 10.62 10.62 0 006.229-2.011z" />
      <path d="M498.927 418.375c-44.656-31.948-126.917-91.51-176.021-131.365-4-3.26-9.792-3.156-13.729.24-9.635 8.406-17.698 15.49-23.417 20.635-17.563 15.854-41.938 15.854-59.542-.021-5.698-5.135-13.76-12.24-23.396-20.615-3.906-3.417-9.708-3.521-13.719-.24-48.938 39.719-131.292 99.354-176.021 131.365a10.703 10.703 0 00-4.406 7.604 10.67 10.67 0 002.802 8.333C19.552 443.01 30.927 448 42.667 448h426.667c11.74 0 23.104-4.99 31.198-13.688a10.676 10.676 0 002.802-8.323 10.73 10.73 0 00-4.407-7.614z" />
    </svg>
  )
}

const Translate = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm0 469.333c-58.116 0-110.82-23.418-149.333-61.238v-34.762c0-10.146 4.542-16.677 9.792-24.24C121.865 341.313 128 332.49 128 320c0-20.344-18.365-22.406-28.24-23.51-7.063-.792-13.729-1.542-17.552-5.365-2.896-2.896-5.792-8.156-8.854-13.719-6.081-11.064-14.324-25.695-30.017-34.656C50.236 131.296 142.837 42.667 256 42.667c5.855 0 11.611.414 17.349.879-1.168.767-2.599 1.288-3.557 2.246a10.69 10.69 0 00-3.125 7.854 10.745 10.745 0 003.573 7.656c3.833 3.406 4.573 5.125 4.719 5.125-.24.51-2.198 3.854-13.115 9.396-18.021 9.135-38.833 27.833-41.927 47.292-1.417 8.833 1.146 17.031 7.208 23.094 2 2 4.708 3.125 7.542 3.125 14.813 0 26.26-5.479 37.333-10.771C283.365 133.135 294.104 128 309.333 128c41.865 0 74.667 9.375 74.667 21.333 0 4.385-1.365 5.729-1.885 6.229-5.24 5.156-23.083 4.823-38.771 4.583-4.156-.073-8.406-.146-12.677-.146-14.479 0-18.969-2.115-24.167-4.573-6.052-2.854-12.906-6.094-29.167-6.094-17.583 0-50.26 3.177-71.542 24.458-17.406 17.396-15.563 38.208-14.354 51.969.281 3.167.563 6.167.563 8.906 0 21.01 21.469 32 42.667 32 32.604 0 60.792 6.083 64 10.667 0 11.938 3.552 20.094 6.406 26.635 2.375 5.469 4.26 9.781 4.26 16.031 0 4.833-.792 5.865-2.927 8.615-4.073 5.292-7.74 11.052-7.74 23.385 0 22.448 21.615 47.073 24.073 49.813a10.688 10.688 0 0010.573 3.188c6.281-1.615 61.354-16.771 61.354-53 0-11.354 3.531-14.417 8.885-19.063 5.25-4.563 12.448-10.802 12.448-23.604 0-8.552 15.177-30.625 29.24-46.177a10.677 10.677 0 002.719-8.094 10.65 10.65 0 00-4.083-7.49c-8.292-6.427-31.188-27.354-38.854-47.656 4.344 2.271 9.781 5.969 14.104 10.292 3.552 3.573 8.313 5.281 13.729 5.063 8.639-.493 18.902-7.319 28.628-15.833 4.975 18.046 7.852 36.956 7.852 56.563-.001 117.635-95.699 213.333-213.334 213.333z" />
    </svg>
  )
}

const Telegram = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z" />
    </svg>
  )
}

const Pinterest = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 511.977 511.977"
    >
      <path d="M262.948 0C122.628 0 48.004 89.92 48.004 187.968c0 45.472 25.408 102.176 66.08 120.16 6.176 2.784 9.536 1.6 10.912-4.128 1.216-4.352 6.56-25.312 9.152-35.2.8-3.168.384-5.92-2.176-8.896-13.504-15.616-24.224-44.064-24.224-70.752 0-68.384 54.368-134.784 146.88-134.784 80 0 135.968 51.968 135.968 126.304 0 84-44.448 142.112-102.208 142.112-31.968 0-55.776-25.088-48.224-56.128 9.12-36.96 27.008-76.704 27.008-103.36 0-23.904-13.504-43.68-41.088-43.68-32.544 0-58.944 32.224-58.944 75.488 0 27.488 9.728 46.048 9.728 46.048S144.676 371.2 138.692 395.488c-10.112 41.12 1.376 107.712 2.368 113.44.608 3.168 4.16 4.16 6.144 1.568 3.168-4.16 42.08-59.68 52.992-99.808 3.968-14.624 20.256-73.92 20.256-73.92 10.72 19.36 41.664 35.584 74.624 35.584 98.048 0 168.896-86.176 168.896-193.12C463.62 76.704 375.876 0 262.948 0z" />
    </svg>
  )
}

const Brazil = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 480 480"
    >
      <path d="M480 88H0v304h480V88zM240 381.1l-194-141 194-141 194 141-194 141z" />
      <path d="M174.8 174.6c57.5 17 110.4 49.3 151.7 92.801 2.7-8.601 4.1-17.801 4.1-27.301 0-50.6-41-91.7-91.7-91.7-24.9 0-47.6 10-64.1 26.2zM160.4 192.8c-8.3 13.8-13.1 30-13.1 47.3 0 50.6 41 91.7 91.7 91.7 32.9 0 61.7-17.3 77.9-43.399-41.8-46.202-96.3-79.601-156.5-95.601z" />
    </svg>
  )
}

const UnitedKingdom = ({ width, height, className }) => {
  return (
    // <svg className={className} width={width} height={height} viewBox="0 -89 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256.543 146.215H512v43.25H256.543zm0 0M0 15.004v167.988h226.535V0H15.004C6.719 0 0 6.719 0 15.004zm59.477 132.648c-8.114 0-14.692-6.578-14.692-14.691s6.578-14.691 14.692-14.691c8.113 0 14.691 6.578 14.691 14.69 0 8.114-6.578 14.692-14.691 14.692zm0-41.21c-8.114 0-14.692-6.579-14.692-14.692 0-8.117 6.578-14.691 14.692-14.691 8.113 0 14.691 6.574 14.691 14.691 0 8.113-6.578 14.691-14.691 14.691zm0-41.215c-8.114 0-14.692-6.579-14.692-14.692 0-8.117 6.578-14.691 14.692-14.691 8.113 0 14.691 6.574 14.691 14.691 0 8.113-6.578 14.692-14.691 14.692zm50.73 82.425c-8.117 0-14.691-6.578-14.691-14.691s6.574-14.691 14.691-14.691c8.113 0 14.691 6.578 14.691 14.69 0 8.114-6.578 14.692-14.691 14.692zm0-41.21c-8.117 0-14.691-6.579-14.691-14.692 0-8.117 6.574-14.691 14.691-14.691 8.113 0 14.691 6.574 14.691 14.691 0 8.113-6.578 14.691-14.691 14.691zm0-41.215c-8.117 0-14.691-6.579-14.691-14.692 0-8.117 6.574-14.691 14.691-14.691 8.113 0 14.691 6.574 14.691 14.691 0 8.113-6.578 14.692-14.691 14.692zm50.727 82.425c-8.114 0-14.692-6.578-14.692-14.691s6.578-14.691 14.692-14.691c8.117 0 14.691 6.578 14.691 14.69 0 8.114-6.574 14.692-14.691 14.692zm0-41.21c-8.114 0-14.692-6.579-14.692-14.692 0-8.117 6.578-14.691 14.692-14.691 8.117 0 14.691 6.574 14.691 14.691 0 8.113-6.574 14.691-14.691 14.691zm0-41.215c-8.114 0-14.692-6.579-14.692-14.692 0-8.117 6.578-14.691 14.692-14.691 8.117 0 14.691 6.574 14.691 14.691 0 8.113-6.574 14.692-14.691 14.692zm0 0M0 292.727v26.953c0 8.285 6.719 15.004 15.004 15.004h481.992c8.285 0 15.004-6.72 15.004-15.004v-26.953zm0 0M256.543 42.953H512v-27.95C512 6.72 505.281 0 496.996 0H256.543zm0 0M256.543 72.957H512v43.25H256.543zm0 0M0 219.473h512v43.246H0zm0 0"/></svg>
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 -89 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m204.355469 104.683594v-104.683594h-182.28125zm0 0" />
      <path d="m163.113281 115.597656-163.113281-93.679687v93.679687zm0 0" />
      <path d="m277.644531 145.597656v-145.597656h-43.289062v145.597656h-234.355469v43.289063h234.355469v145.597656h43.289062v-145.597656h234.355469v-43.289063zm0 0" />
      <path d="m348.886719 218.886719 163.113281 93.679687v-93.679687zm0 0" />
      <path d="m307.644531 229.800781v104.683594h182.28125zm0 0" />
      <path d="m307.644531 0v104.683594l182.28125-104.683594zm0 0" />
      <path d="m348.886719 115.597656h163.113281v-93.679687zm0 0" />
      <path d="m204.355469 229.800781-182.28125 104.683594h182.28125zm0 0" />
      <path d="m0 218.886719v93.679687l163.113281-93.679687zm0 0" />
    </svg>
  )
}

const Dark = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
    >
      <path d="M459.782 347.328c-4.288-5.28-11.488-7.232-17.824-4.96-17.76 6.368-37.024 9.632-57.312 9.632-97.056 0-176-78.976-176-176 0-58.4 28.832-112.768 77.12-145.472 5.472-3.712 8.096-10.4 6.624-16.832S285.638 2.4 279.078 1.44C271.59.352 264.134 0 256.646 0c-132.352 0-240 107.648-240 240s107.648 240 240 240c84 0 160.416-42.688 204.352-114.176 3.552-5.792 3.04-13.184-1.216-18.496z" />
    </svg>
  )
}

const Light = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 302.4 302.4"
    >
      <path d="M204.8 97.6C191.2 84 172 75.2 151.2 75.2s-40 8.4-53.6 22.4c-13.6 13.6-22.4 32.8-22.4 53.6s8.8 40 22.4 53.6c13.6 13.6 32.8 22.4 53.6 22.4s40-8.4 53.6-22.4c13.6-13.6 22.4-32.8 22.4-53.6s-8.4-40-22.4-53.6zM151.2 51.6c5.6 0 10.4-4.8 10.4-10.4V10.4c0-5.6-4.8-10.4-10.4-10.4-5.6 0-10.4 4.8-10.4 10.4v30.8c0 5.6 4.8 10.4 10.4 10.4zM236.4 80.8l22-22c4-4 4-10.4 0-14.4s-10.4-4-14.4 0l-22 22c-4 4-4 10.4 0 14.4 3.6 4 10 4 14.4 0zM292 140.8h-30.8c-5.6 0-10.4 4.8-10.4 10.4 0 5.6 4.8 10.4 10.4 10.4H292c5.6 0 10.4-4.8 10.4-10.4 0-5.6-4.8-10.4-10.4-10.4zM236 221.6c-4-4-10.4-4-14.4 0s-4 10.4 0 14.4l22 22c4 4 10.4 4 14.4 0s4-10.4 0-14.4l-22-22zM151.2 250.8c-5.6 0-10.4 4.8-10.4 10.4V292c0 5.6 4.8 10.4 10.4 10.4 5.6 0 10.4-4.8 10.4-10.4v-30.8c0-5.6-4.8-10.4-10.4-10.4zM66 221.6l-22 22c-4 4-4 10.4 0 14.4s10.4 4 14.4 0l22-22c4-4 4-10.4 0-14.4-3.6-4-10-4-14.4 0zM51.6 151.2c0-5.6-4.8-10.4-10.4-10.4H10.4c-5.6 0-10.4 4.8-10.4 10.4s4.8 10.4 10.4 10.4h30.8c5.6 0 10.4-4.8 10.4-10.4zM66 80.8c4 4 10.4 4 14.4 0s4-10.4 0-14.4l-22-22c-4-4-10.4-4-14.4 0s-4 10.4 0 14.4l22 22z" />
    </svg>
  )
}

const Filter = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 459 459"
    >
      <path d="M178.5 382.5h102v-51h-102v51zM0 76.5v51h459v-51H0zM76.5 255h306v-51h-306v51z" />
    </svg>
  )
}

export {
  LogoSvg,
  Play,
  Close,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Linkedin,
  Whatsapp,
  Mail,
  Translate,
  Telegram,
  Pinterest,
  Brazil,
  UnitedKingdom,
  Dark,
  Light,
  Filter,
}
