import React, { useContext } from "react"
import { Link } from "gatsby"
import { Brazil, UnitedKingdom, Dark, Light } from "./icons"
import GlobalContextProvider, {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import styled from "styled-components"

import { rhythm, scale } from "../utils/typography"

export default function Layout({ location, children, title }) {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const { menu, footer } = state.language
  return (
    <>
      <header>
        <section className="site-header">
          <div className="wrapper">
            <Link
              className="site-title"
              to="/"
              style={{ textDecoration: "none" }}
            >
              <h2>
                <b>[</b>gabriel<b>.</b>fonseca<b>]</b>
              </h2>
            </Link>
            <nav class="site-nav">
              <a href="#" class="menu-icon">
                <svg viewBox="0 0 18 15">
                  <path
                    fill="#424242"
                    d="M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.031C17.335,0,18,0.665,18,1.484L18,1.484z"
                  />
                  <path
                    fill="#424242"
                    d="M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0c0-0.82,0.665-1.484,1.484-1.484 h15.031C17.335,6.031,18,6.696,18,7.516L18,7.516z"
                  />
                  <path
                    fill="#424242"
                    d="M18,13.516C18,14.335,17.335,15,16.516,15H1.484C0.665,15,0,14.335,0,13.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.031C17.335,12.031,18,12.696,18,13.516L18,13.516z"
                  />
                </svg>
              </a>

              <div class="trigger" data-grid="spacing center">
                {menu.map((item, index) =>
                  index === 0 || index === 4 || index === 5 ? (
                    <Link to={item.link} className="page-link">
                      <b>•</b> {item.text}
                    </Link>
                  ) : (
                    <a
                      href={item.link}
                      className="page-link"
                      data-cell="shrink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>•</b> {item.text}
                    </a>
                  )
                )}
                <a
                  className="icon"
                  onClick={() => {
                    dispatch({ type: "TOGGLE_LANGUAGE" })
                  }}
                >
                  {state.language.current === "portuguese" ? (
                    <UnitedKingdom className="social-icons" height="25" />
                  ) : (
                    <Brazil className="social-icons" height="25" />
                  )}
                </a>
              </div>
            </nav>
          </div>
        </section>
      </header>
      <div id="content">{children}</div>
      <footer></footer>
    </>
  )
}
